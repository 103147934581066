<template>
  <section class="page-interaction">
    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner />
      </template>
      <b-tabs content-class="mt-3" v-model="activeTabIndex">
        <b-tab t:itle="$t('My purchase history')">
          <b-row v-for="(receivedGiftMedia, i) in receivedGiftMedias" :key="i">
            <buying-content-card :buying-content="receivedGiftMedia" @setLoading="setLoading($event)"/>
          </b-row>
          <b-row v-if="!loading && receivedGiftMedias.length < 1">
            <discover-no-user :show-image="false">
              <h3 class="color-black">
                {{ $t('You haven\'t received any photos or videos yet') }}
              </h3>
            </discover-no-user>
          </b-row>
        </b-tab>
        <b-tab :title="$t('Those Who Buy My Photos and Videos')">
          <b-row v-for="(sentGiftMedia, i) in sentGiftMedias" :key="i">
            <buying-content-card :buying-content="sentGiftMedia" @setLoading="setLoading($event)"/>
          </b-row>
          <b-row v-if="!loading && sentGiftMedias.length < 1">
            <discover-no-user :show-image="false">
              <h3 class="color-black">
                {{ $t('No one has received their photos and videos yet') }}
              </h3>
            </discover-no-user>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </section>
</template>

<script>
import {
  BAvatar, BCol, BImg, BMedia, BOverlay, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { showNotify, throwDefaultError } from '@/helpers/helpers'
import DiscoverNoUser from '@/components/DiscoverNoUser'
import moment from 'moment'
import BuyingContentCard from '@/components/BuyingContentCard'

// import { getUser } from '@/auth/utils'

export default {
  name: 'BuyingContentIndex',
  components: {
    BAvatar,
    BCol,
    BImg,
    BMedia,
    BOverlay,
    BRow,
    BTabs,
    BTab,

    BuyingContentCard,
    DiscoverNoUser,
  },
  data() {
    return {
      activeTabIndex: 0,
      limit: 200,
      loading: false,
      receivedGiftMedias: [],
      sentGiftMedias: [],
    }
  },
  created() {
    this.getSentGiftMedias()
    this.getReceivedGiftMedias()
  },
  methods: {
    getReceivedGiftMedias() {
      this.loading = true
      useJwt.getReceivedGiftMedias(0, this.limit)
          .then(response => {
            // Endpoint url'i yanlış isimlendirilmiş o yüzden sent aslında received
            this.sentGiftMedias = response.data.body.map(gift => {
              gift['user'] = gift['ownerUser']
              return gift
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getSentGiftMedias() {
      this.loading = true
      useJwt.getSentGiftMedias(0, this.limit)
          .then(response => {
            // Endpoint url'i yanlış isimlendirilmiş o yüzden received aslında sent
            this.receivedGiftMedias = response.data.body.map(gift => {
              gift['user'] = gift['targetUser']
              return gift
            })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    setLoading(loading) {
      this.loading = loading
    },
  }
}
</script>
