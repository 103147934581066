<template>
  <b-col lg="6" md="12" class="buying-content-card">
    <div class="buying-content-card-inner">
      <div class="buying-content-left">
        <router-link :to="{name: 'users.show', params: {id: buyingContent.user.id}}">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="48"
                  :src="buyingContent.user.profileImage"
                  :text="avatarText(buyingContent.user.userName)"
              />
            </template>
          </b-media>
        </router-link>
        <div class="buying-content-details">
          <router-link :to="{name: 'users.show', params: {id: buyingContent.user.id}}" class="buying-content-link">
            @{{ buyingContent.user.userName }}
          </router-link>
          <div class="buying-content-time">
            {{ getDate(buyingContent.createdAt) }}
          </div>
        </div>
      </div>
      <div class="buying-content-right">
        <span v-if="false" class="buying-content-credit">
          <b-img :src="iconDiamonds1" width="18" height="18" />
          {{ buyingContent.credits }}
        </span>
        <span v-if="buyingContent.productType === 'user_photo'" class="fs-14 fw-400 color-black">
          {{ $t('Photo') }}
        </span>
        <span v-if="buyingContent.productType === 'user_video'" class="fs-14 fw-400 color-black">
          {{ $t('Video') }}
        </span>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BAvatar, BCol, BImg, BMedia } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError} from '@/helpers/helpers'
import moment from 'moment/moment'
import { avatarText } from '@core/utils/filter'
import _ from "lodash";

export default {
  name: 'buying-content-card',
  props: ['buyingContent'],
  components: {
    BAvatar,
    BCol,
    BImg,
    BMedia
  },
  data() {
    return {
      iconDiamonds1: require('@/assets/images/icons/diamonds-1.svg'),
    }
  },
  methods: {
    avatarText(text) {
      return avatarText(text)
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
  }
}
</script>

<style lang="scss" scoped>
.buying-content-card {
  background-color: #F5F8FD;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;

  .buying-content-card-inner {
    display: flex;
    justify-content: space-between;

    .buying-content-left {
      display: flex;
      align-items: center;

      .buying-content-details {
        .buying-content-link {
          font-family: 'Lexend', sans-serif;
          font-size: 18px;
          font-style: normal;
          color: #000000;
          font-weight: 600;
          line-height: 22px;
        }
      }
      .buying-content-time {
        font-family: 'Lexend', sans-serif;
        color: #A4A4A4;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

      }
    }

    .buying-content-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .buying-content-credit {
        padding: 5px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 100px;
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
        margin-top: 2px;
      }

      .buying-content-image {
        display: flex;
        justify-content: center;
        width: 50px;
      }
    }
  }
}
</style>
